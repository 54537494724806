<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="5">
        <v-card class="rounded-xl pt-4" style="height: 680px" flat>
          <div class="center-logo mt-6">
            <app-logo></app-logo>
          </div>
          <h3 class="u-page-heading">Activate your account</h3>
          <p>Your konvey account is almost ready!</p>
          <v-form lazy-validation ref="form" class="text-left px-10 pb-7">
            <label>Email <span class="text-danger">*</span></label>
            <v-text-field
              solo
              flat
              dense
              disabled
              background-color="secondary"
              :rules="validate.required('email')"
              placeholder="Email"
              type="email"
              v-model="form.email"
            ></v-text-field>
            <label>Password <span class="text-danger">*</span></label>
            <v-text-field
              solo
              flat
              dense
              :disabled="disabled_action"
              background-color="#eff3ff"
              :rules="validate.required('password')"
              placeholder="Password"
              :type="!showPassword ? 'password' : 'text'"
              v-model="form.password"
            >
              <template v-slot:append>
                <v-icon
                  @click="showPassword = !showPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
            <v-row>
              <v-col cols="12" md="6">
                <label>First name <span class="text-danger">*</span></label>
                <v-text-field
                  solo
                  flat
                  dense
                  :disabled="disabled_action"
                  background-color="secondary"
                  :rules="validate.required('name')"
                  type="text"
                  v-model="form.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label>Last name</label>
                <v-text-field
                  solo
                  flat
                  dense
                  :disabled="disabled_action"
                  background-color="secondary"
                  type="text"
                  v-model="form.oname"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="justify-center text-center mb-6">
              <v-btn
                text
                class="px-7 py-5 primary capitalize"
                @click="$refs.form.validate() ? continueToCheckout() : null"
                :loading="loading"
                :disabled="disabled_action"
                style="width: 100%; font-size: 14px"
                >ACTIVATE</v-btn
              >
              <p class="mt-3 u-terms-condition">
                By joining Konvey, you agree to our <br />
                <a href="">Terms of Service</a> and
                <a href="">Privacy Policy</a>
              </p>
            </div>
            <v-card-text class="text-center">
              <p class="support-contact-box">
                <v-icon size="18px" color="black" class="mr-1">
                  mdi-email-fast
                </v-icon>
                Need help? &#8211;
                <a href="mailto:support@konvey.app">support@konvey.app</a>
              </p>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import AppLogo from "../../components/Logo";
import FormValidation from "../../utils/FormValidation";
import ResponseHelper from "../../utils/ResponseHelper";
import { mapActions } from "vuex";

export default {
  name: "PaidUserAccountSetup",
  components: {
    AppLogo,
  },
  data() {
    return {
      form: {},
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      loading: false,
      showPassword: false,
      overlay: false,
      disabled_action: false,
    };
  },
  async created() {
    await this.verifyPaymentToken();
  },
  methods: {
    ...mapActions("auth", ["sign_in"]),
    async continueToCheckout() {
      this.loading = true;
      try {
        const token = await this.getToken();
        const response = await window.axios.post("paid-user-account-setup", {
          token: token,
          email: this.form.email,
          name: this.form.name,
          oname: this.form.oname,
          password: this.form.password,
        });
        this.toast.sendSuccess(response);
        await this.$router.push({ name: "Login" });
      } catch (error) {
        this.toast.sendError(error);
      } finally {
        this.loading = false;
      }
    },
    async verifyPaymentToken() {
      this.overlay = true;
      try {
        const token = await this.getToken();
        const response = await window.axios.get(
          "verify-payment-token/" + token
        );
        this.form.email = response.data.email;
        console.log(response);
      } catch (e) {
        this.disabled_action = true;
        this.$response.sendError(e);
      } finally {
        this.overlay = false;
      }
    },
    async getToken() {
      return this.$route.params.token;
    },
  },
};
</script>

<style scoped>
.bold-text {
  font-weight: 900;
}
.support-contact-box {
  font-size: 12px;
  margin-top: 6px;
  align-content: center;
}
.u-page-heading {
  font-weight: bold;
  color: #2e2e9e;
}
.text-danger {
  color: red;
}
.u-terms-condition {
  font-size: 13px !important;
}
.u-terms-condition a {
  text-decoration: none !important;
}
</style>
